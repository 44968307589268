import { mapLoader } from './mapLoader.ts';
    let Map;
    let Polyline;
    let Point;
    let LatLng;
    let event;
    let AdvancedMarkerElement;

export async function initMap(apiKey, mapContainer, zoom = 0, center, mapId, minZoom = 10) {
    const {
        AdvancedMarkerElement:GAdvancedMarkerElement,
        Map: GMap,
        Polyline: GPolyline,
        Point: GPoint,
        LatLng: GLatLng,
        event: GEvent
    } = await mapLoader(apiKey);
    Map = GMap;
    Polyline = GPolyline;
    Point = GPoint;
    LatLng = GLatLng;
    event = GEvent;
    AdvancedMarkerElement = GAdvancedMarkerElement;
    return new Map(mapContainer, {
        zoom: zoom,
        center: getPointLatLng(center.lat, center.lng),
        minZoom: minZoom,
        mapId: mapId,
    });
}

export function getStreetMarker(map, mapCoordinates, title, zIndex, content = getPinWithFilledCircleSvg()) {
    return new AdvancedMarkerElement({
        map: map,
        position: getPointLatLng(mapCoordinates.lat, mapCoordinates.lng),
        content: content,
        title: title.toString(),
        zIndex: zIndex,
    })
}
export function eventTrigger (marker, markerEvent){
    event.trigger(marker, markerEvent);
}

export function getMapCenter(map, latlng, offsetX, offsetY) {
    const scale = Math.pow(2, map.getZoom());
    const worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
    const pixelOffset = new Point((offsetX / scale) || 0, (offsetY / scale) || 0);

    const worldCoordinateNewCenter = new Point(
        worldCoordinateCenter.x - pixelOffset.x,
        worldCoordinateCenter.y + pixelOffset.y
    );

    return map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
}

export function getStreetPolyline(street, map, streetPath = null) {
    let polylineData = street?.polyline ? JSON.parse(street.polyline) : null;

    if (streetPath) {
        streetPath.setMap(null);
    }

    if (polylineData) {
        streetPath = new Polyline({
            path: polylineData,
            strokeColor: "#5d83fc",
            map: map,
            strokeOpacity: 1,
            strokeWeight: 8,
            geodesic: true,
        });
        streetPath.setMap(map);
    }

    return streetPath;
}

export function getPointLatLng(streetLat, streetLng) {
    return new LatLng(streetLat, streetLng);
}

export function getPinWithFilledCircleSvg() {
    const parser = new DOMParser();
    const pinSvgString = `<svg width="56" height="56" viewBox="0 0 56 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1"
                  d="M44 18C44 9.16344 36.8366 2 28 2C19.1634 2 12 9.16344 12 18C12 26.8366 19.1634 34 28 34C36.8366 34 44 26.8366 44 18Z"
                  fill="#0061F3"/>
            <path d="M33 18C33 15.2386 30.7614 13 28 13C25.2386 13 23 15.2386 23 18C23 20.7614 25.2386 23 28 23C30.7614 23 33 20.7614 33 18Z"
                  fill="#0061F3"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M28 23C30.7614 23 33 20.7614 33 18C33 15.2386 30.7614 13 28 13C25.2386 13 23 15.2386 23 18C23 20.7614 25.2386 23 28 23ZM28 26C32.4183 26 36 22.4183 36 18C36 13.5817 32.4183 10 28 10C23.5817 10 20 13.5817 20 18C20 22.4183 23.5817 26 28 26Z"
                  fill="white"/>
        </svg>`;

    const parsedSvg = parser.parseFromString(pinSvgString, "image/svg+xml").documentElement;
    parsedSvg.style.transform = "translateY(60%)";
    return parsedSvg;
}

export function getSelectedPinWithFilledCircleSvg() {
    const parser = new DOMParser();
    const pinSvgString = `<svg width="65.25" height="66" viewBox="0 0 87 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.1" x="21.75" y="21" width="24" height="24" rx="12" fill="#0061F3"/>
            <rect opacity="0.1" x="-1" width="65.25" height="66" rx="32.25" fill="#0061F3"/>
            <rect x="17.25" y="18" width="30" height="30" rx="15" fill="#0061F3"/>
            <rect x="17.25" y="18" width="30" height="30" rx="15" stroke="white" stroke-width="6"/>
        </svg>`;

    const parsedSvg = parser.parseFromString(pinSvgString, "image/svg+xml").documentElement;
    parsedSvg.style.transform = "translate(10%, 60%)";
    return parsedSvg;
}
