export function formatDate(dateString, months) {
    const [year, month, day] = dateString.split('-');
    const monthName = months[parseInt(month, 10) - 1];

    return `${day} ${monthName} ${year}`;
}

export function addGoogleTags(event, event_category, event_label) {
    gtag('event', event, {
        'event_category': event_category,
        'event_label': event_label,
    });
}

export function isMobileVersion() {
    return window.innerWidth <= 992;
}

export function closeRateWindow() {
    const rateWindow = document.getElementById('rate');
    if (rateWindow) {
        rateWindow.classList.add('d-none');
    }
}

export function getStreetName (street, app_locale)  {
    if (!street) {
        return ''
    }

    if (!app_locale || (!street.new_name[app_locale] && !street.old_name[app_locale])) {
        return street.id;
    }

    return `${street.old_name[app_locale]} - ${street.new_name[app_locale]}`;
}
