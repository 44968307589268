const eponimTypes = {
    0: '',
    1: 'eponim_type.person',
    2: 'eponim_type.object',
    3: 'eponim_type.historical_name',
    4: 'eponim_type.subject',
};

export function isValidEponim(eponim) {
    return eponim.includes('.') ?? false;
}

export function getEponimType(eponimType) {
    return eponimTypes[eponimType] ?? '';
}
