
import { Loader } from '@googlemaps/js-api-loader';

async function mapLoader(apiKey: string) {
    const loader = new Loader({
        apiKey: apiKey,
        version: 'weekly',
        id: 'HOME_MAP_ID',
        libraries: ['maps', 'core', 'marker']
    });

    const { AdvancedMarkerElement } = await loader.importLibrary("marker");
    const { Map, Polyline } = await loader.importLibrary("maps");
    const { Point, LatLng, event } = await loader.importLibrary("core");
    return {
        AdvancedMarkerElement,
        Map,
        Polyline,
        Point,
        LatLng,
        event
    };
}

export { mapLoader };
